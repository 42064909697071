<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-home</v-icon> Datos principales de la almazara</h1><br>
        <v-layout>
          <v-flex xs3>
            <v-text-field
              v-model="nombre"
              label="Razón social"
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="nif"
              label="NIF"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs2>
            <v-text-field
              v-model="rgseaa"
              label="RGSEAA"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex xs5>
            <v-file-input 
              label="Logo de la almazara"
              outlined
              @change="previewLogo"
              v-model="logo"
              >
            </v-file-input>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs7>
            <v-text-field
              v-model="direccion"
              label="Dirección"
              outlined
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs3>
            <v-text-field
              v-model="telefono"
              label="Teléfono"
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-flex>
          <v-flex xs4>
            <v-text-field
              v-model="email"
              label="Email"
              outlined
              :rules="rules.required"
            ></v-text-field>
          </v-flex>
          <v-flex xs5>
            <v-img class="logo" contain :src="url"></v-img>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="save"><v-icon class="btnIcon">mdi-content-save</v-icon> Guardar</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout class="layInicio" wrap>
      <v-flex xs4>
        <sparkline class="sparkline" ref="sparkline1" />
      </v-flex>
      <v-flex xs4>
        <sparkline class="sparkline" ref="sparkline2" />
      </v-flex>
      <v-flex xs4>
        <sparkline class="sparkline" ref="sparkline3" />
      </v-flex>
    </v-layout>
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import genericReload from '../components/genericReload';
import sparkline from '../components/Sparkline.vue'
import showMessage from '../components/Messages.vue'
export default {
  name: 'Almazara',
  data () {
    return {
      rules: {
        required: [val => (val || '').length > 0 || 'Este campo es obligatorio'],
      },
      url: null,
      logo: null,
      nombre: '',
      nif: '',
      rgseaa: '',
      direccion: '',
      telefono: '',
      email: '',
    }
  },
  components: {
    sparkline,
    showMessage
  },
  mounted(){
    this.loadStatics();
    this.read();
  },
  methods:{
    previewLogo(){
      if(this.logo){
        this.url = URL.createObjectURL(this.logo);
      }else{
        this.url = null;
      }
    },
    async read(){
      const response = await genericReload(this.$store.state.auth, `almazara`);
      if (response.status === 200) {
        const json = await response.json();
        this.nombre = json.body.nombre;
        this.nif = json.body.NIF;
        this.rgseaa = json.body.RGSEAA;
        this.telefono = json.body.telefono;
        this.email = json.body.email;
        this.direccion = json.body.direccion;
        this.getLogo();
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. No se han podido cargar los datos de la almazara", "Cerrar", "red");
      }
    },
    async save(){
      const data = {
        nombre: this.nombre,
        NIF: this.nif,
        RGSEAA: this.rgseaa,
        telefono: this.telefono,
        email: this.email,
        direccion: this.direccion,
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };

      const response = await fetch(
        `${BASE_URL}/almazara`,
        {
          method: 'PUT',
          headers: headers,
          body: JSON.stringify(data),
        }
      );

      let formData = new FormData();
      formData.append('logo', this.logo);
      const headersLogo = {
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      const responseLogo = await fetch(
        `${BASE_URL}/almazara`,
        {
          method: 'PUT',
          headers: headersLogo,
          body: formData,
        }
      );
      switch (response.status) {
        case 200:
          this.$store.commit('modifyNameUser',this.nombre);
          this.$refs.snackbar.setNew(true, "Datos guardados correctamente", "Ok", "green");
          break;
        default:
          this.$refs.snackbar.setNew(true, "ERROR. Datos no guardados", "Cerrar", "red");
          break;
      }
    },
    async getLogo(){
      const response = await genericReload(this.$store.state.auth, `almazara/logo`);
      if (response.status === 200) {
        const blob = await response.blob()
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);
        this.url = imageUrl;
      }
    },
    async getBetweenDates(what){
      let response, json;
      let data = [0,0,0,0,0,0,0,0,0,0,0,0];
      const anno = new Date().getFullYear();
      const productores = this.$store.state.productor;
      switch (what) {
        case "productores":
          for(let i=0; i<productores.length; i++){
            if(productores[i].fechaAlta>=anno+"-01-01" && productores[i].fechaAlta<=anno+"-01-31"){ data[0]+=1; }
            if(productores[i].fechaAlta>=anno+"-02-01" && productores[i].fechaAlta<=anno+"-02-29"){ data[1]+=1; }
            if(productores[i].fechaAlta>=anno+"-03-01" && productores[i].fechaAlta<=anno+"-03-31"){ data[2]+=1; }
            if(productores[i].fechaAlta>=anno+"-04-01" && productores[i].fechaAlta<=anno+"-04-30"){ data[3]+=1; }
            if(productores[i].fechaAlta>=anno+"-05-01" && productores[i].fechaAlta<=anno+"-05-31"){ data[4]+=1; }
            if(productores[i].fechaAlta>=anno+"-06-01" && productores[i].fechaAlta<=anno+"-06-30"){ data[5]+=1; }
            if(productores[i].fechaAlta>=anno+"-07-01" && productores[i].fechaAlta<=anno+"-07-31"){ data[6]+=1; }
            if(productores[i].fechaAlta>=anno+"-08-01" && productores[i].fechaAlta<=anno+"-08-31"){ data[7]+=1; }
            if(productores[i].fechaAlta>=anno+"-09-01" && productores[i].fechaAlta<=anno+"-09-30"){ data[8]+=1; }
            if(productores[i].fechaAlta>=anno+"-10-01" && productores[i].fechaAlta<=anno+"-10-31"){ data[9]+=1; }
            if(productores[i].fechaAlta>=anno+"-11-01" && productores[i].fechaAlta<=anno+"-11-30"){ data[10]+=1; }
            if(productores[i].fechaAlta>=anno+"-12-01" && productores[i].fechaAlta<=anno+"-12-31"){ data[11]+=1; }
          }
          return data;
        case "kilos":
          for(let j=0; j<productores.length; j++){
            response = await genericReload(this.$store.state.auth, 'entrada/?idProductor='+productores[j].id);
            json = await response.json();
            for(let i=0; i<json.body.length; i++){
              if(json.body[i].fecha>=anno+"-01-01" && json.body[i].fecha<=anno+"-01-31"){ data[0]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-02-01" && json.body[i].fecha<=anno+"-02-29"){ data[1]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-03-01" && json.body[i].fecha<=anno+"-03-31"){ data[2]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-04-01" && json.body[i].fecha<=anno+"-04-30"){ data[3]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-05-01" && json.body[i].fecha<=anno+"-05-31"){ data[4]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-06-01" && json.body[i].fecha<=anno+"-06-30"){ data[5]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-07-01" && json.body[i].fecha<=anno+"-07-31"){ data[6]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-08-01" && json.body[i].fecha<=anno+"-08-31"){ data[7]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-09-01" && json.body[i].fecha<=anno+"-09-30"){ data[8]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-10-01" && json.body[i].fecha<=anno+"-10-31"){ data[9]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-11-01" && json.body[i].fecha<=anno+"-11-30"){ data[10]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-12-01" && json.body[i].fecha<=anno+"-12-31"){ data[11]+=json.body[i].kilos; }
            }
          }
          return data;
        case "liquidado":
          for(let j=0; j<productores.length; j++){
            response = await genericReload(this.$store.state.auth, 'liquidacion/?estado=aceptado&idProductor='+productores[j].id);
            json = await response.json();
            for(let i=0; i<json.body.length; i++){
              if(json.body[i].fecha>=anno+"-01-01" && json.body[i].fecha<=anno+"-01-31"){ data[0]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-02-01" && json.body[i].fecha<=anno+"-02-29"){ data[1]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-03-01" && json.body[i].fecha<=anno+"-03-31"){ data[2]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-04-01" && json.body[i].fecha<=anno+"-04-30"){ data[3]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-05-01" && json.body[i].fecha<=anno+"-05-31"){ data[4]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-06-01" && json.body[i].fecha<=anno+"-06-30"){ data[5]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-07-01" && json.body[i].fecha<=anno+"-07-31"){ data[6]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-08-01" && json.body[i].fecha<=anno+"-08-31"){ data[7]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-09-01" && json.body[i].fecha<=anno+"-09-30"){ data[8]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-10-01" && json.body[i].fecha<=anno+"-10-31"){ data[9]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-11-01" && json.body[i].fecha<=anno+"-11-30"){ data[10]+=json.body[i].kilos; }
              if(json.body[i].fecha>=anno+"-12-01" && json.body[i].fecha<=anno+"-12-31"){ data[11]+=json.body[i].kilos; }
            }
          }
          return data;
      }
    },
    async loadStatics(){
      const anno = new Date().getFullYear();
      this.$refs.sparkline1.setNew("Productores en "+anno, "white", await this.getBetweenDates("productores"));
      this.$refs.sparkline2.setNew("Kilos recibidos en "+anno, "white", await this.getBetweenDates("kilos"));
      this.$refs.sparkline3.setNew("Liquidado en "+anno, "white", await this.getBetweenDates("liquidado"));
    },
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em !important;
}
.sparkline{
  margin-right: 1em;
}
.logo{
  margin-top: -50px;
  height: 15vh;
}
</style>