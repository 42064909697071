<template>
  <v-card
    class="text-center"
    :color="colorFondo"
    light
    style="margin-top: 5%"
  >
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(0, 0, 0, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item">
            {{ item.value }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <div class="display-1">{{periodicidad}}</div>
    </v-card-text>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      periodicidad: '',
      colorFondo: 'white',
      value: [],
    }),
    methods: {
      setNew(periodicidad, colorFondo, value){
        this.periodicidad = periodicidad;
        this.colorFondo = colorFondo;
        this.value = value;
      }
    }
  }
</script>